import { Reducer, combineReducers, configureStore } from "@reduxjs/toolkit";

import { customListReducer } from "./customList/customListSlice";
import { externalUsersReducer } from "./externalUsers/externalUsersSlice";
import { navHeaderReducer } from "./navHeaderNotification/navHeaderSlice";
import { programLocaleNavReducer } from "./programLocaleNav/programLocaleNavSlice";
import { userReducer } from "./user/userSlice";

import { authReducer, logout } from "~/auth/authRedcuer";

const combinedReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  externalUsers: externalUsersReducer,
  customList: customListReducer,
  navHeader: navHeaderReducer,
  programLocaleNav: programLocaleNavReducer
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === logout.fulfilled) {
    // If a logout is triggered from an idle activity, we don't want to reset the user state,
    // since we want the login modal to be displayed instead of the user being routed back to the login page.
    if (action.payload.fromIdleActivity) {
      state = {
        user: {
          ...state.user,
          userStatus: ""
        }
      };
      // This will trigger that the user is routed back to the login screen
    } else {
      state = {};
    }
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;

export default store;
