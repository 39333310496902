import { DateTime } from "luxon";
import { SVGProps } from "react";

import {
  BehaviouralSurveyMetadata,
  CarePriorityType,
  ChurnRiskMetadata,
  FirstWeekMetadata,
  LastUnseenMessageInformation,
  ProgramProgressionMetadata,
  UserSymptomLogMetadata
} from "./graphql/types";

import { SMSStatus, TextMessageType } from "~/constants/textMessaging";

export type ExternalUserType = {
  id: string;
  name: string;
  title: string;
  defaultPhoneCountryCode: string;
  programTemplateId: string;
  smsText: string;
  behaviour: number;
  createdDate?: string;
  modifiedDate?: string;
};

export type AuthenticatedUser = {
  authAccountId: string;
  createdDate: string;
  displayName: string;
  fullName: string;
  email: string;
  id?: string;
  userId?: string;
  imageHref: string;
  imageId: string;
  locale: string;
  name: string;
  nick: string;
  privacyPolicyDate: string;
  privacyPolicyVersion: number;
  privileges: string;
  requiresElectronicId: boolean;
  timezoneOffset: string;
  timezoneId: string;
  title?: string;
};

export interface EnrichedAuthenticatedUser extends AuthenticatedUser {
  fullName: string;
}

export type UserDetailsUser = {
  id?: string;
  userId: string;
  displayName: string;
  fullName: string;
  authAccountId: string;
  imageHref?: string;
};

export type Message = {
  text: string;
  source: number;
  id: string;
  senderUserId: string;
  recipientUserId: string;
  createdDate: string;
  seenDate: string;
  unseenByNonRecipient: boolean;
};

export type MessageThread = {
  recipientUser: UserDetailsUser;
  senderUser: UserDetailsUser;
  noReply: boolean;
  lastMessageDate: string;
  messages?: ChatMessage[];
};

export type DailySummary = {
  date: string;
  physicalActivityMinutes: number;
  scoreCount: number;
  stepCount: number;
};

export type UserDetails = {
  scoremeals: ScoreMeal[];
  id: string;
  user: UserDetailsUser;
  timezoneOffset: string;
  healthLevel: number;
  totalKicks: number;
  gender: number;
  birthDate: string;
  lastActive: string;
  weight: number;
  height: number;
  hasSurveyActions: boolean;
  dailySummary: DailySummary[];
  userCreatedDate: string;
  weightScore?: {
    weight: number;
    date: string;
  };
  bloodPressure?: {
    rating: number;
    systolic: number;
    diastolic: number;
    heartrate: number;
    date: string;
  };
  bloodSugar?: {
    rating: number;
    value: number;
    date: string;
  };
  fastingBloodSugar?: {
    rating: number;
    value: number;
    date: string;
  };
  mealBloodSugar?: {
    rating: number;
    value: number;
    date: string;
  };
  cholesterol?: {
    rating: number;
    value: number;
    date: string;
  };
  ratingWorkRelatedStress?: {
    rating: number;
    value: number;
    date: string;
    valueRange: number;
  };
  latestSurveyResults: SurveyResult[];
  assignedCoach?: Coach;
  userPrograms: UserProgram[];
  bmi?: number;
  messageThreads?: MessageThread[];
  externalUser?: {
    externalUserTypeId: string;
    id: string;
  };
  hasMealLogActions?: boolean;
  movePointsLastSevenDays?: number;
  foodPointsLastSevenDays?: number;
  mindPointsLastSevenDays?: number;
  missions: Mission[];
  canCareManagerOrderDevice?: boolean;
  userReadmissionOutput?: UserReadmission;
  timezoneId?: string;
  joinedDate?: string;
};

export type ExternalUserHistoryEntry = {
  id: number;
  modifiedDate: string;
  modifiedByUser: {
    userId: number;
    displayName: string; //User's display name, most often nickname
    fullName: string; //User's full name
    title: string; //A selected few people have title
    imageHref: string; //The href to the user profile image
    imageId: string; //The data store imageId of the user profile image, this property may need to be removed if the user profile image becomes anything else
    score: number;
    unit: string; //When there is score and the unit is not kicks, this is the unit, Challenge.UNIT_...
    friendStatus: number; //When the user mini output is used to list friends or when friendStatus is specifically asked for this is filled in
    externalId: string; //TODO:remove
    externalType: number; //TODO:remove
    authAccountId: number;
  };
  enrolmentConsentToCall: number;
  enrolmentHipaaVerified: number;
  enrolmentCallRecordingDisclaimer: number;
  enrolmentStatus: number;
  eventSource: number;
  eventType: number;
};

export type ExternalUserHistory = {
  externalUserHistories: ExternalUserHistoryEntry[];
};

export type ChatMessage = {
  text: string;
  source: number;
  phoneNumber?: string;
  type?: number;
  smsStatus?: number;
  message?: string;
  replyingToMessageId?: string;
  lowestScore: number;
  lowestScoreSentence: string;
  negativeRatio: number;
  overallSentiment: number;
  technicalProblem: boolean;
  unseenByNonRecipient: boolean;
  id: string;
  createdDate: string;
  senderUserId: string;
  recipientUserId: string;
  seen: boolean;
  seenDate?: string;
  replied: boolean;
  repliedToMessageId?: string;
  replyingUserId?: string;
  translatedText?: string;
  translatedLanguage?: string;
};

export type SMSMessage = {
  phoneNumber: string;
  type: TextMessageType;
  smsStatus: SMSStatus;
  message: string;
  id: string;
  createdDate: string;
  senderUserId: string;
  recipientUserId: string;
  seen: boolean;
  seenDate?: string;
};

export type MessageType = ChatMessage | SMSMessage;

export interface PhysicalData {
  date: DateTime;
  physicalActivityMinutes: number;
  scoreCount: number;
  stepCount: number;
}

export type PhysicalDataServer = {
  date: string;
  physicalActivityMinutes: number;
  scoreCount: number;
  stepCount: number;
};

export type ScoreMeal = {
  title?: string;
  id: string;
  userId: string;
  scoreId: string;
  clientDate: string;
  preferredDate: string;
  serverDate: string;
  hungerBefore: number;
  hungerAfter: number;
  portionSize: number;
  coachPortionSize?: number;
  healthyness: number;
  coachHealthyness?: number;
  coachReview?: string;
  mealType: number;
  attachmentUrl: string;
  coachModifiedDate?: string;
  coachUser?: {
    userId: string;
    displayName: string;
    fullName: string;
    title: string;
    imageHref: string;
    imageId: string;
    authAccountId: string;
  };
};

export type FoodJournalUpdatePayload = {
  id: string;
  scoreId: string;
  coachReview?: string;
  coachHealthyness?: number;
  coachPortionSize?: number;
};

export type UserWeekPoints = {
  foodPoints?: number;
  movePoints?: number;
  mindPoints?: number;
};

export type DeviceOrderStatusData = {
  id: string;
  clientOrderId: string;
  status: number;
  userId: string;
  createdOn: string;
  createdByUserId: string;
  modifiedOn: string;
  modifiedByUserId: string;
  externalOrderId?: string;
  orderedExternalDevices: [
    {
      id: string;
      name: string;
      subtitle: string;
      imageHref: string;
      description: string;
      externalSku: string;
      externalDeviceVendorId: string;
    }
  ];
  carrier?: string;
  trackingNumber?: string;
  trackingUrl?: string;
};

export enum ExternalDeviceOrder {
  Unknown = 0,
  Pending = 1,
  Ordered = 2,
  Shipped = 3,
  Delivered = 4,
  Error = 10,
  Cancelled = 20
}
export type MissionTask = {
  categoryId: number;
  targetValue: number;
  currentValue: number;
  unit: string;
  name: string;
  missionId: string;
  userMissionId: string;
  isQuiz: boolean;
};

export type MissionSummarySlotDay = {
  date: string;
  dayInSlot?: number;
  moveProgress?: number;
  foodProgress?: number;
  clinicProgress?: number;
  tasks?: MissionTask[];
};

export type MissionSummary = {
  id: string;
  programId: string;
  programTemplateId: string;
  userId: string;
  noSlots: number;
  currentSlot: number;
  status: number;
  slots: [
    {
      slotNumber: number;
      startDate: string;
      slotDays: MissionSummarySlotDay[];
    }
  ];
};

export type MissionsSlotDay = {
  date: string;
  tasks?: MissionTask[];
  dayInSlot: number;
};

export type Document = {
  id: string;
  documentName: string;
  modifiedDate: string;
};

export type BloodPressureData = {
  date: string;
  diastolic: number;
  heartrate: number;
  rating: number;
  systolic: number;
  totalScore: number;
};

export type BloodSugarData = {
  date: string;
  rating: number;
  totalScore: number;
  value: number;
};

export type Survey = {
  id: string;
  surveyName: string;
  title: string;
  details: string;
  iconUrl: string;
  disclaimer: string;
  resultText: string;
  copyright?: string;
  type: number;
  questions: SurveyQuestion[];
  optional?: boolean;
  displayTextView: boolean;
  options: SurveyOptions;
  quiz?: boolean;
  ratings?: SurveyRating[];
  answerSeverities?: SurveyResultAnswerSeverity[];
};

export type SurveyQuestionAnswer = {
  id: string;
  name: string;
  title: string;
  order: number;
  ratingValue: number;
  type: number;
  embeddedQuestion?: SurveyQuestion;
};

export type SurveyQuestion = {
  id: string;
  name: string;
  title: string;
  displayType: number;
  displayTypeOutput: {
    options: number;
    weight: boolean;
    singleSelection: boolean;
    multiSelection: boolean;
    spinner: boolean;
    singleSelectionImage: boolean;
    multiSelectionImage: boolean;
    draggableScale: boolean;
    discreteScale: boolean;
    textInput: boolean;
  };
  answerType: number;
  answerTypeOutput: {
    options: number;
    range: boolean;
    simple: boolean;
  };
  options: number;
  optionsOutput: {
    options: number;
    excludeFromResults: boolean;
    conditional: boolean;
    embedded: boolean;
  };
  order: number;
  answers: SurveyQuestionAnswer[];
  optional: boolean;
  enabledByAnswerIds?: string[];
};

export type SurveyOptions = {
  options: number;
  oncePerDay: boolean;
  quiz: boolean;
  noSubmitScreen: boolean;
  displayTextView: boolean;
  useMaxSeverityAsResult: boolean;
};

export type SurveyRating = {
  id: string;
  name: string;
  title: string;
  colour: string;
  lowerValue?: number;
  upperValue?: number;
  coachRatingValue: number;
  coachMessage?: string;
};

export type SurveyResultAnswer = {
  name?: string;
  title?: string;
  answer: string;
  ratingValue: number;
  surveyAnswerSeverityId?: string;
  attachmentUrl?: string;
};

export type SurveyResultQuestion = {
  name: string;
  title: string;
  ratingValue: number;
  displayType: number;
  answerType: number;
  answers: SurveyResultAnswer[];
  unit?: string;
};

export type SurveyResultAnswerSeverity = {
  colour: string;
  id: string;
  name: string;
  order: number;
  value: number;
};

export type SurveyResult = {
  id: string;
  surveyName: string;
  date: string;
  localDate: string;
  result: number;
  coachRatingDate?: string;
  answerSeverity?: SurveyResultAnswerSeverity;
  questions?: SurveyResultQuestion[];
  coachRatingValue?: number;
  coachRatingUser?: Coach;
  surveyId: string;
};

export type Location = {
  name: string;
  countryCode: string;
  defaultPhoneCountryCode: string;
  state: string;
  id: string;
  createdDate: string;
  deleted: boolean;
};

export type TherapeuticArea = {
  id: string;
  name: string;
  shortName: string;
  createdDate: string;
  deleted: boolean;
};

export type Program = {
  coachProgramId: string;
  programCatalogItemId: string;
  locale: string;
  locales?: string[]; // Only used by Anthem programs
  name: string;
  imageHref: string;
  description: string;
  tier: number;
  location?: Location;
  userCount: number;
  activeUsers: number;
  newUsers: number;
  highRiskUsers: number;
  coaches: Coach[];
  communityId: string;
  userProgramGroups: UserProgramGroup[];
  usingBodyTraceScale: boolean;
  useUserBaseWeight: boolean;
  behaviour: number;
  externalUserTypes: ExternalUserType[];
  defaultSurveyId?: string;
  useExternalUserKeywordSearch: boolean;
  slotNames?: string[];
  programCatalogItemProjectType?: string;
  showMedicationCenterInCarePortal: boolean;
  useUserListColumnCurrentPregnancyWeek?: boolean;
  useUserListColumnRiskLevel?: boolean;
  layersForDisplay?: Layer[];
  carePortalName?: string;
  restartedStatusDurationSeconds?: string;
  retriggeredStatusDurationSeconds?: string;
  variablesSelectableOnInvite?: Variable[];
  variableSelectionRequiredOnInvite?: boolean;
  lineOfBusiness?: string;
  therapeuticArea?: TherapeuticArea;
};

export type Variable = {
  id: string;
  name: string;
  title?: string;
  userSettingTypeId: string;
};

export type ExternalUser = {
  enrolmentStatusText: string;
  enrolmentStatus: number;
  enrolmentConsentToCallText: string;
  enrolmentConsentToCall: number;
  id: string;
  identifier: string;
  phoneNumber: string;
  submittedByDate: string;
  smsStatus: number;
  unseenSms: boolean;
  name: string;
  email: string;
  anthemMbrKey: string;
  anthemMCID: string;
  anthemHRSID: string;
  linkedUserId: number;
  smsOptOutType: string;
  linkedUser?: any;
  birthDate: string;
  caseIdentifier: string;
  enrolmentHipaaVerified: number;
  category: number;
  enrolmentCallRecordingDisclaimer: number;
  careCompassExternalUserID: string;
  diseaseName: string;
  pinCode: string;
  pinCodeUrl: string;
  submittedByUser: { displayName: string };
  smsDate: string;
  smsStatusDetails: string;
  externalUserTypeId: string;
  memberState?: string;
  planState?: string;
  outreachType?: string;
  brand?: string;
  lineOfBusiness?: string;
  patientBond?: string;
  groupName?: string;
  groupNumber?: string;
  proxyId?: string;
  relationship?: string;
  eligibleForRestart?: boolean;
  eligibleForRestartDate?: string;
  conditions?: string;
};

export type UserProgram = {
  id: string;
  userId: string;
  programId: string;
  programTemplateId: string;
  programCatalogItemId: string;
  locale: string;
  startDate: string;
  endDate: string;
  joinedDate: string;
  currentSlot: number;
  noSlots: number;
  status: number;
  type: number;
  tier: number;
  pinCode: string;
  userProgramGroupId: string;
  programName: string;
  slotName: string;
  currentSlotStartDate: string;
  leftDate?: string;
};

export type Mission = {
  id: string;
  name: string;
  reminderText: string;
  kicksPerValue: number[];
  maxKicksPerDay: number;
  unit: string[];
  imageName: string;
  order: number;
  categoryId: number;
  typeId: number;
  customId: number;
  version: number;
  count: number;
  countValue: number;
  timeValue: number;
};

export type MissionScore = {
  scoreId: string;
  kicks: number;
  date: string;
  userProgramCatalogItemDayId: string;
  scoreMissions: {
    missionId: string;
    value1: number;
  }[];
};

export type NoteInfo = {
  id: string;
  createdDate: string;
  text: string;
  itemType: number;
  coachUser: {
    displayName: string;
    imageHref: string;
    title: string;
    userId: string;
  };
  archived?: boolean;
};

export interface Coach extends BaseUserInfo {
  title: string;
  imageHref: string;
  imageId: string;
  privileges?: string;
}

export type UserProgramGroup = {
  id: string;
  name: string;
  coach?: Coach;
};

export type BaseUserInfo = {
  userId: string;
  displayName: string;
  fullName: string;
  authAccountId: string;
};

export type Layer = {
  id: string;
  name: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  thumbnailUrl?: string;
  displayColor?: string;
  carePortalAbbreviation?: string;
};

export type UserProgramCoach = {
  id: string;
  userProgramCatalogItemId: string;
  userId: string;
  coachId: string;
  specialtyId: string;
  createdOn: string;
  createdBy: string;
  isDeleted: boolean;
};

export enum LastMessageType {
  SMS = 0,
  Chat = 1
}

export type CoachUser = {
  id: string;
  user: BaseUserInfo;
  oldestPendingAction: Date;
  latestActionDate: string;
  joinedDate: string;
  lastActiveDate: string;
  dayInSlot: number;
  currentSlot: number;
  tier: number;
  movePointsLastSevenDays: number;
  mindPointsLastSevenDays: number;
  foodPointsLastSevenDays: number;
  programStatus: number;
  userProgramStatusReason?: number;
  latestSurveyResults: SurveyResult[];
  hasMessageActions: boolean;
  hasNonRecipientsMessageActions: boolean;
  hasMealLogActions: boolean;
  hasSurveyActions: boolean;
  latestScaleDate?: string;
  lastMessageDate?: string;
  lastMessageType?: LastMessageType;
  lastUnseenSmsDate?: string;
  diseaseName: string;
  unseenSms?: boolean;
  unseenMessageCount?: number;
  assignedCoachUserId?: string;
  leftDate?: string;
  displayName?: string;
  unseenNonRecipientMessageCount: number;
  possibleUnseenTechnicalProblem?: boolean;
  overallSentiment?: number;
  oldestUnseenMessageFromUserDate?: string;
  oldestUnseenNonRecipientMessageFromUserDate?: string;
  oldestPendingFoodLogDate?: string;
  oldestPendingSurveyResult?: string;
  carePortalUserListItemCreatedDate?: string;
  carePortalUserListItemModifiedDate?: string;
  carePortalUserListItem?: boolean;
  memberState?: string;
  planState?: string;
  lineOfBusiness?: string;
  pregnancyDueDate?: string;
  riskLevel?: string;
  coachUserLayersForDisplay?: Layer[];
  relationship?: string;
  readmitStatus?: string;
  userProgramCatalogItemId?: number;
  isInMaintenanceSlot: boolean;
  coaches?: CareTeamMember[];
  eligibleForRestart?: boolean;
  eligibleForRestartDate?: string;
  restartedDate?: string;
  groupNumber?: string;
  groupName?: string;
  weight?: number;
  weightDate?: string;
  mealBloodSugar?: BloodSugarData;
  fastingBloodSugar?: BloodSugarData;
  bloodPressure?: BloodPressureData;
};

export type GroupCoach = {
  active: boolean;
  isMe: boolean;
  groupId: string;
  userId: string;
  displayName: string;
  authAccountId?: string;
  fullName?: string;
  imageHref?: string;
  imageId?: string;
  title?: string;
};

export type SurveyOption = {
  value: string;
  text: string;
  colour?: string;
};

export type UserSettings = {
  name: string;
  createdDate: string;
  modifiedDate: string;
  booleanValue?: boolean;
  value?: string;
}[];

export type MessageSuggestion = {
  suggestionId: string;
  message: string;
};

export type UserReadmission = {
  id: string;
  eventType: number;
  userId: number;
  externalUserId: number;
  entityTypeId: number;
  entityId: number;
  userProgramCatalogItemId: number;
  createdById: string;
  hasError: boolean;
  errorType: number;
  createdDate: string;
};
export type UserGroup = {
  id: string;
  name: string;
  coachUserId: string;
  behaviour: number;
  createdDate: string;
  modifiedDate: string;
  modifiedByUserId: string;
  locale: string;
  memberCount: number;
  coach?: Coach;
};

export type UserGroupUpdatePayload = {
  name: string;
  coachUserId: string;
  behaviour: number;
};

export type MedicationType = {
  name: string;
  order: number;
  title: string;
  typeId: number;
};

export type Reminder = {
  periodically: {
    time: string[];
    startDate: string;
    every: number;
    period: number;
  };
  weekdaySchedule: {
    time: string[];
    startDate: string;
    weekDays: number[];
  };
  userReminderScheduleId: string;
};

export type Medication = {
  name: string;
  strength: number;
  unit: string;
  type: MedicationType;
  id: string;
  userId: string;
  iconId?: number;
  iconColor?: string;
  expirationDate?: DateTime;
  medicationId?: string;
  note?: string;
  reminders: Reminder[];
};

export type MedicationLog = {
  id: string;
  scheduledDate: string;
  completedDate: string;
  userMedicationId: string;
  status: MedicationLogStatus;
};

export type MedicationLogStatus = {
  name: string;
  value: number;
};

export type MedicationLogs = {
  medication: Medication;
  logs: MedicationLog[];
};

export type ProgramSpecialty = {
  programCatalogItemId: string;
  specialtyId: string;
  id: string;
  behaviours: number;
  createdOn: string;
  createdBy: string;
  deleted: boolean;
};

export type Specialty = {
  name: string;
  contentItemId: string;
  deleted: boolean;
  id: string;
  createdOn: string;
  modifiedOn?: string;
  createdBy: string;
  modifiedBy?: string;
  localizedName: string;
  specialtyId: string;
};

export type UserSpecialty = {
  specialtyId: string;
  specialtyName: string;
  localizedSpecialtyName: string;
};

export type CareTeamMember = {
  coachUserId: string;
  specialtyId: string;
};

export enum ImportEntryStatus {
  New = 0,
  Processing = 1,
  Finished = 2,
  Parsing = 3,
  Error = 10
}

export type ImportEntry = {
  id: number;
  fileUploadId: number; // each file uploaded is saved and given an ID
  name: string;
  createdDate: string;
  modifiedDate: string;
  status: ImportEntryStatus;
  externalUserTypeId: string;
  externalUserTypeName: string;
  importUserName: string; // name of the user that created the import, if available
  usersToImport: number; // total number of users to import
  importedUsers: number; // number of users successfully imported
  duplicateUsers: number; // number of duplicate users
  errorUsers: number; // number of users that ended in an error
  retriggeredUsers: number; // number of retriggered users
};

export type ExternalUserGroupOutput = {
  id: number;
  fileUploadId?: number;
  createdDate: string;
  createdByUserId: number;
  approvedDate?: string;
  approvedByUserId?: number;
  name: string;
  shortName?: string;
  number: string;
  ibcJV: boolean;
  careFirst: boolean;
  clientEffectiveDate?: string;
  fullOffshoreRestrictions: boolean;
  memberFacingOffshoreRestrictions: boolean;
  clientTerminationDate?: string;
};

export enum MessageSuggestionType {
  WeeklyFeedback = "weekly_feedback",
  ChurnRisk = "churn_risk",
  FirstWeek = "first_week"
}

export interface DigaMenoIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

export type CarePriorityData =
  | {
      type: CarePriorityType.BehaviouralSurveyFeedback;
      metadata: BehaviouralSurveyMetadata;
    }
  | {
      type: CarePriorityType.ProgramProgression;
      metadata: ProgramProgressionMetadata;
    }
  | {
      type: CarePriorityType.FirstWeek;
      metadata: FirstWeekMetadata;
    }
  | {
      type: CarePriorityType.HighChurnRisk;
      metadata: ChurnRiskMetadata;
    }
  | {
      type: CarePriorityType.SymptomLogFeedback;
      metadata: UserSymptomLogMetadata;
    }
  | {
      type: CarePriorityType.NewMessage;
      metadata: LastUnseenMessageInformation;
    }
  | {
      type: CarePriorityType.WeeklyFeedback;
    }
  | {
      type: CarePriorityType.SurveyResultCreated;
    };
